import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Product from "./components/Product";
import Middle from "./components/Middle";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from "./components/Footer";
import About from "./components/About";
import Privacy from "./components/Privacy";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for Home Page */}
        <Route path="/" element={<HomeLayout />} />
        {/* Route for Product Page */}
        <Route path="/Product" element={<Product />} />
        {/* Routes for About and Privacy at the top level */}
        <Route path="/About" element={<About />} />
        <Route path="/Privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

// HomeLayout component includes Navbar and Home components
function HomeLayout() {
  return (
    <>
      <Navbar />
      <Middle />
      <Home />
      <Footer />
    </>
  );
}

export default App;
