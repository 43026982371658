import React, { useEffect } from "react";

import Footer from "./Footer";

function About() {
  const paragraphStyle = {
    color: "#0000f3",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div class="row">
        <div class="col-xl-6"></div>
        <div class="col-xl-6 header-menu">
          <ul class="navbar-list">
            <li>
              <a
                class="text-decoration-underline"
                style={{ color: "black" }}
                href="/"
              >
                Register As A Professional
              </a>
            </li>
            <li>
              <a style={{ color: "black" }} href="/">
                Help
              </a>
            </li>
            <li>
              <a style={{ color: "black" }} href="/">
                Login / Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <h2 className="text-center">About Us</h2>
      <hr className="mt-5" />
      <div className="container">
        <div className="whowearesection mt-5">
          <h3 className="fw-bold text-center">Who we are</h3>
          <p>
            Headed by Soumitra Manna, Usha Shelters provides housing and
            designing services for its customers. With a team consisting of
            engineers, architects, designers and supervisors, Usha Shelters
            provides the best housing experience to its clients. Besides
            housing, Usha Shelters also helps with designing, decorating, basic
            facilities like plumbing and electricity and security services. Usha
            Shelters is a go-to-place for anyone in Kolkata looking for housing.
          </p>
          {/* <div className="container"> */}
          <div className="aboutSection mt-5 ">
            <div className="numberBox">
              <h3 className="fw-bold text-center">1569+</h3>
              <p>Trained Professionals</p>
            </div>
            <div className="numberBox">
              <h3 className="fw-bold text-center">2379+</h3>
              <p>Happy Customers</p>
            </div>
            <div className="numberBox">
              <h3 className="fw-bold text-center">9+</h3>
              <p>Cities</p>
            </div>
            <div className="numberBox">
              <h3 className="fw-bold text-center">1000+</h3>
              <p>Projects</p>
            </div>
            {/* </div> */}
          </div>

          <h3 className="fw-bold text-center mt-5">How We do it</h3>
          <p>
            Ushashelters provides a platform that allows skilled and experienced
            professionals to connect with users looking for specific services.
            Once on the platform, our match-making algorithm identifies
            professionals who are closest to the users’ requirements and
            available at the requested time and date.
          </p>
          <p style={paragraphStyle}>Ushashelters Impact</p>

          <h3 className="fw-bold text-center mt-5">Our Leadership Team</h3>
          <div className="teamSection">
            <div className="teamCard1">
              <img className="ceoLogo" src="images/CEO logo.jpeg " alt="" />
              <h6 className="mt-2">Soumitra Manna</h6>
              <p>CEO & Co-founder, ushashelters </p>
              
            </div>
            <div className="teamCard1">
              <img className="ceoLogo" src="images/Subrata.jpg " alt="" />
              <h6 className="mt-2">Subrata Manna</h6>
              <p>Civil Engineer, ushashelters </p>
            </div>
            <div className="teamCard1">
              <img className="ceoLogo" src="images/Soumen.jpg " alt="" />
              <h6 className="mt-2">Soumen Adak</h6>
              <p>Designer, ushashelters </p>
            </div>
          </div>

          <h3 className="text-center fw-bold mt-5">Our Association</h3>
          <div className="InvestorSection">
            <img
              className="mx-4 my-2"
              width="150"
              height="100"
              src="images/Diamond.jpeg"
              alt=""
            />

            <img
              className="mx-4 my-2"
              width="150"
              height="100"
              src="images/PS.jpeg"
              alt=""
            />

            <img
              className="mx-4 my-2"
              width="150"
              height="100"
              src="images/Mani.jpeg"
              alt=""
            />

            <img
              className="mx-4 my-2"
              width="150"
              height="100"
              src="images/Tata_Sky_logo.jpg"
              alt=""
            />
          </div>
          {/* <div className="lastSection mt-5">
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Susanta Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>

      </div> */}
          {/* <div className="lastSection mt-5">
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Susanta Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>
        <div className="lastSectionBox">
        <p>Soumitra Manna</p>
        <img class="ceoLogo" src="images/CEO logo.jpeg " alt=""></img>
        </div>

      </div> */}

          {/* <p className="text-center">For media queries, contact:  hello@skypathdigital.com </p>

      <h6 className="text-center">You could be a part of our journey. Interested?</h6>

      <button className="btn btn-primary text-uppercase mx-auto d-block mt-4 mb-5">Apply now</button> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
