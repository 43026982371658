import React from "react";
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <>

   <div className="container mt-5 ">
   <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <div className="col-md-6 d-flex align-items-center">
        <Link to="/About" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
          About
        </Link>
        <Link to="/Privacy" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
          Privacy
        </Link>
      </div>

      <ul className="nav col-md-6 justify-content-end list-unstyled d-flex">
        <li className="ms-3">
          <a className="text-body-secondary" href="#">
           <img width="24" height="24" src="https://ik.imagekit.io/chf/image-2.png?updatedAt=1690871431800" alt="" />
          </a>
        </li>
        <li className="ms-3">
          <a className="text-body-secondary" target="__blank" href="https://www.instagram.com/ushashelters_2010/">
           <img width="24" height="24"  src="https://ik.imagekit.io/chf/image-3.png?updatedAt=1690871406381" alt="" />
          </a>
        </li>
        <li className="ms-3">
          <a className="text-body-secondary" target="__blank" href="https://www.facebook.com/ushashelters?mibextid=ZbWKwL">
            <img width="24" height="24" src="https://ik.imagekit.io/chf/image-5.png?updatedAt=1690871461827" alt="" />
          </a>
        </li>
        <li className="ms-3">
          <a className="text-body-secondary" target="__blank" href="https://www.linkedin.com/company/ushashelters/">
            <img width="24" height="24" src="https://ik.imagekit.io/chf/image-4.png?updatedAt=1690871195315" alt="" />
          </a>
        </li>
      </ul>
    </footer>

   </div>
    
    </>
  );
};

export default Footer;
