import React, { useState } from "react";
import { IoClose } from 'react-icons/io5'; // Import the provided SVG icon
import "./Navbar.css"; // Import a CSS file for styling

export default function Navbar() {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const services = [
    "Masonry",
    "Carpentry",
    "Electrician",
    "Plumber",
    "CCTV Installation",
    "Signage Maintenance",
    "Interior Decoration",
    "Office Modular Furniture",
    "Epoxy Tabletop",
    "Epoxy Floor",
    "MS Structure Shed",
    "Manual and Mechanical Rolling Shutter",
    "Painting",
    "False Ceiling",
    "Tiles and Marble Work",
    "Interior Designs",
    "Building Repairs & Maintenance",
    "Epoxy Flooring",
    "Painting & Waterproofing",
    "Electrical & CCTV",
  ];

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter services based on the search query
    const results = services.filter((service) =>
      service.toLowerCase().includes(query)
    );

    setSearchResults(results);
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleCall = () => {
    // Implement your calling logic here
    // You can use window.location.href or other methods to initiate a call
    // For simplicity, I'm using the tel protocol
    window.location.href = "tel:+919874464122";
  };

  return (
    <>
      <div className="section">
        <div className="row">
          <div className="col-xl-6">
           <img src="" alt="" />
          </div>
          <div className="col-xl-6 header-menu">
            <ul className="navbar-list">
              <li>
                <a className="text-decoration-underline" href="/">
                  Register As A Professional
                </a>
              </li>
              <li>
                <a href="/">Help</a>
              </li>
              <li>
                <a href="/">Login / Sign Up</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row" style={{ marginTop: "90px" }}>
          <h1 className="text-center text-white">
            Home Services, on Fingertips.
          </h1>
        </div>

        <div className="row input-header" style={{ marginTop: "48px" }}>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 text-center largedevices my-2">
            <div className="box1">
              <img
                className="img2"
                id="locationIcon"
                src="images/location.png"
                alt="Location Icon"
              />
              <img className="img3" src="images/flag.png" alt="Flag" />

              <select
                name="city"
                id="city"
                style={{ border: "none" }}
              >
                <option value="kolkata">Kolkata</option>
                <option value="mumbai">Mumbai</option>
                <option value="delhi">Bhubneswar</option>
                <option value="jaunpur">Patna</option>
                <option value="jaunpur">Jamshedpur</option>
                <option value="jaunpur">Assam</option>
              </select>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6  largedevices my-2">
            <div className="box2">
              <div className="search-box">
                <img
                  className="img10"
                  style={{ paddingLeft: "15px", height: "15px" }}
                  src="images/search.png"
                  alt="Search Icon"
                />
                <input
                  id="SearchBox"
                  style={{ width: "90%", border: "none", marginLeft: "20px" }}
                  type="text"
                  placeholder="Search for Services"
                  name=""
                  onChange={handleSearch}
                  value={searchQuery}
                />
                {searchQuery && searchResults.length > 0 && (
                  <ul className="search-results">
                    {searchResults.map((result, index) => (
                      <li key={index}>
                        <a
                          href="#"
                          className=""
                          onClick={() => handleServiceClick(result)}
                        >
                          {result}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
                {isPopupOpen && selectedService && (
                  <div className="popup-box">
                    <div className="popup-header">
                      {/* Close icon for closing the pop-up */}
                      <IoClose onClick={handleClosePopup} className="close-icon" />
                    </div>
                    <h6>Welcome to ushashelters.com</h6>
                    <p>{`You selected ${selectedService}. Call For Service.`}</p>
                    <button className="mx-2 my-2" onClick={handleCall}>
                      Call
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <ul
            style={{
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              marginBottom: "140px",
            }}
          ></ul>
        </div>
      </div>
    </>
  );
}
