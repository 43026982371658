import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';

export default function Middle() {
  const [selectedService, setSelectedService] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleBoxClick = (serviceName) => {
    setSelectedService(serviceName);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleCall = () => {
    // Your logic for handling the call
    console.log(`Calling for service: ${selectedService}`);
    window.location.href = "tel:+919874464122";
    // You can implement your specific logic here, like opening a pop-up, making an API call, etc.
  };

  return (
    <>
      <div className="container mt-4 middle-section">
        <div className="row" style={{ padding: '14px' }}>
          <div className="row justify-content-center mt-4">
            <div className="col-md-2 text-center box25" onClick={() => handleBoxClick('Interior Designs')}>
              <img className="images6" src="images/icon10.png" alt="Interior Designs" />
              <p className="mt-3">Interior Designs</p>
            </div>
            <div className="col-md-2 text-center box25" onClick={() => handleBoxClick('Building Repairs & Maintenance')}>
              <img className="images6" src="images/icon6.png" alt="Building Repairs & Maintenance" />
              <p className="mt-3">Building Repairs & Maintenance</p>
            </div>
            <div className="col-md-2 text-center box25" onClick={() => handleBoxClick('Epoxy Flooring')}>
              <img className="images6" src="images/icon8.png" alt="Epoxy Flooring" />
              <p className="mt-3">Epoxy Flooring</p>
            </div>
            <div className="col-md-2 text-center box25" onClick={() => handleBoxClick('Painting & Waterproofing')}>
              <img className="images6" src="images/icon7.png" alt="Painting & Waterproofing" />
              <p className="mt-3">Painting & Waterproofing</p>
            </div>
            <div className="col-md-2 text-center box25" onClick={() => handleBoxClick('Electrical & CCTV')}>
              <img className="images6" src="images/icon9.png" alt="Electrical & CCTV" />
              <p className="mt-3">Electrical & CCTV</p>
            </div>
          </div>
        </div>
      </div>

      {isPopupOpen && selectedService && (
        <div className="popup-box">
          <div className="popup-header">
            <IoClose onClick={handleClosePopup} className="close-icon" />
          </div>
          <h6>Welcome to ushashelters.com</h6>
          <p>{`You selected ${selectedService}. Call For Service.`}</p>
          <button className="mx-2 my-2" onClick={handleCall}>
            Call
          </button>
        </div>
      )}
    </>
  );
}
