import React, { useEffect } from "react";
import Footer from "./Footer";

export default function Privacy() {
  useEffect(()=>{
    window.scrollTo(0,0)

  },[])
  return (
    <>
      <div class="row">
        <div class="col-xl-6"></div>
        <div class="col-xl-6 header-menu">
          <ul class="navbar-list">
            <li>
              <a
                class="text-decoration-underline"
                style={{ color: "black" }}
                href="/"
              >
                Register As A Professional
              </a>
            </li>
            <li>
              <a style={{ color: "black" }} href="/">
                Help
              </a>
            </li>
            <li>
              <a style={{ color: "black" }} href="/">
                Login / Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="container">
        <h2>Privacy Policy</h2>
        <p className="mt-4">
          Welcome to <b>Ushashelters.com.</b> This Privacy Policy describes how
          we collect, use, disclose, and protect your personal information. By
          using our Website and services, you agree to the terms of this Privacy
          Policy.
        </p>
        <h4>1. Information We Collect</h4>
        <h6>1.1 Personal Information</h6>
        <p>
          We may collect personal information such as your name, email address,
          phone number, and address when you use our services or communicate
          with us.
        </p>
        <h6>1.2 Usage Information</h6>
        <p>
          We collect information about your interactions with our Website,
          including the pages you visit, the services you use, and the actions
          you take.
        </p>
        <h6>1.3 Device Information</h6>
        <p>
          We may collect information about the device you use to access our
          Website, including the device type, operating system, and browser
          type.
        </p>
        <h4>2. How We Use Your Information</h4>
        <p>We use your information for the following purposes:</p>
        <ul>
          <li>To provide and improve our services</li>
          <li>To personalize your experience</li>
          <li>
            To communicate with you, including responding to inquiries and
            sending service-related information
          </li>
          <li>To analyze and enhance the security of our Website</li>
        </ul>

        <h4>3. Cookies and Similar Technologies</h4>
        <p>
          We use cookies and similar technologies to enhance your experience on
          our Website. You can control cookies through your browser settings.
        </p>
        <h4>4. Third-Party Links and Services</h4>
        <p>
          Our Website may contain links to third-party websites or services. We
          are not responsible for the privacy practices of these third parties.
          Please review the privacy policies of these websites.
        </p>
        <h4>5. Security</h4>
        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure.
        </p>
        <h4>6. Your Choices</h4>
        <p>
          You can choose not to provide certain personal information, but this
          may limit your ability to use certain features of our services.
        </p>
        <h4>7. Changes to This Privacy Policy</h4>
        <p>
          We may update this Privacy Policy from time to time. We will notify
          you of any changes by posting the new Privacy Policy on this page.
        </p>
        <h4>8. Contact Us</h4>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at <b> hello@skypathdigital.com. </b> By using
         <b> Ushashelters.com,</b>  you agree to the terms of this Privacy Policy. If
          you do not agree with the terms of this Privacy Policy, please do not
          use our Website. Thank you for choosing  <b>Ushashelters.com!</b>
        </p>

        <Footer/>
      </div>
    </>
  );
}
