import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const Home = () => {
  useEffect(()=>{

    
const carousel = document.querySelector(".carousel"),
firstImg = carousel.querySelectorAll("img")[0],
arrowIcons = document.querySelectorAll(".wrapper i");

let isDragStart = false, isDragging = false, prevPageX, prevScrollLeft, positionDiff;

const showHideIcons = () => {
    // showing and hiding prev/next icon according to carousel scroll left value
    let scrollWidth = carousel.scrollWidth - carousel.clientWidth; // getting max scrollable width
    arrowIcons[0].style.display = carousel.scrollLeft === 0 ? "none" : "block";
    arrowIcons[1].style.display = carousel.scrollLeft === scrollWidth ? "none" : "block";
}

arrowIcons.forEach(icon => {
    icon.addEventListener("click", () => {
        let firstImgWidth = firstImg.clientWidth + 14; // getting first img width & adding 14 margin value
        // if clicked icon is left, reduce width value from the carousel scroll left else add to it
        carousel.scrollLeft += icon.id === "left" ? -firstImgWidth : firstImgWidth;
        setTimeout(() => showHideIcons(), 60); // calling showHideIcons after 60ms
    });
});

const autoSlide = () => {
    // if there is no image left to scroll then return from here
    if(carousel.scrollLeft - (carousel.scrollWidth - carousel.clientWidth) > -1 || carousel.scrollLeft <= 0) return;

    positionDiff = Math.abs(positionDiff); // making positionDiff value to positive
    let firstImgWidth = firstImg.clientWidth + 14;
    // getting difference value that needs to add or reduce from carousel left to take middle img center
    let valDifference = firstImgWidth - positionDiff;

    if(carousel.scrollLeft > prevScrollLeft) { // if user is scrolling to the right
        return carousel.scrollLeft += positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
    }
    // if user is scrolling to the left
    carousel.scrollLeft -= positionDiff > firstImgWidth / 3 ? valDifference : -positionDiff;
}

const dragStart = (e) => {
    // updatating global variables value on mouse down event
    isDragStart = true;
    prevPageX = e.pageX || e.touches[0].pageX;
    prevScrollLeft = carousel.scrollLeft;
}

const dragging = (e) => {
    // scrolling images/carousel to left according to mouse pointer
    if(!isDragStart) return;
    e.preventDefault();
    isDragging = true;
    carousel.classList.add("dragging");
    positionDiff = (e.pageX || e.touches[0].pageX) - prevPageX;
    carousel.scrollLeft = prevScrollLeft - positionDiff;
    showHideIcons();
}

const dragStop = () => {
    isDragStart = false;
    carousel.classList.remove("dragging");

    if(!isDragging) return;
    isDragging = false;
    autoSlide();
}

carousel.addEventListener("mousedown", dragStart);
carousel.addEventListener("touchstart", dragStart);

document.addEventListener("mousemove", dragging);
carousel.addEventListener("touchmove", dragging);

document.addEventListener("mouseup", dragStop);
carousel.addEventListener("touchend", dragStop);

  },[]);

  return (
    <div>
      {/* <div className="container-fluid mt-5">
        <div className="row d-flex justify-content-center">
          <div className="card ImageBox" style={{ width: "18rem", border: "none" }}>
            <Link to="/Product"  >
              <img src="images/card.png" className="card-img-top ImageHover" alt="..." />
              
            </Link>
            <div className="card-body"></div>
          </div>
          <div className="card" style={{ width: "18rem", border: "none" }}>
            <Link to="/Product" >
              <img src="images/card1.png" className="card-img-top ImageHover" alt="..." />
            </Link>
            <div className="card-body"></div>
          </div>
          <div className="card" style={{ width: "18rem", border: "none" }}>
            <Link to="/Product" >
              <img src="images/card2.png" className="card-img-top ImageHover" alt="..." />
            </Link>
            <div className="card-body"></div>
          </div>
          <div className="card" style={{ width: "18rem", border: "none" }}>
            <Link to="/Product" >
            <img src="images/card3.png" className="card-img-top ImageHover" alt="..." />
            <div className="card-body"></div>
            </Link>
          </div>
        </div>
      </div> */}
      <h2 className="text-center">Home Repairs</h2>
      <div className="container-fluid mt-5 ">
        <div className="row d-flex justify-content-center">
          <div className="card " style={{ width: "18rem",height:"18rem", border: "none" }}>
            <img
              src="images/NewImages5.jpg"
              className="card-img-top RepairImages"
              alt="..."
            />
            <p className='text-center fw-bold mt-3'>Interior Designs</p>
            
          </div>
          <div className="card " style={{ width: "18rem",height:"18rem", border: "none" }}>
            <img
              src="images/NewImages7.jpg"
              className="card-img-top RepairImages"
              alt="..."
            />
             <p className='text-center fw-bold mt-3'>Building Repairs & Maintenance</p>
            
          </div>
          <div className="card " style={{ width: "18rem",height:"18rem", border: "none" }}>
            <img
              src="images/Epoxy Flooring.jpeg"
              className="card-img-top RepairImages"
              alt="..."
            />
           <p className='text-center fw-bold mt-3'>Epoxy Flooring</p>
          </div>
          <div className="card " style={{ width: "18rem",height:"18rem", border: "none" }}>
            <img
              src="images/NewImages4.jpg"
              className="card-img-top RepairImages"
              alt="..."
            />
           <p className='text-center fw-bold mt-3'>Painting & Waterproofing</p>
          </div>
          
          
        </div>
      </div>

      <hr className="mt-2" />
      <h2 className="text-center">Cleaning & Pest Control</h2>

      <div className="container mt-5">
        <div className="wrapper">
          <i id="left" className="fa-solid fa-angle-left"></i>
          <div className="carousel" style={{ position: "initial" }}>
            <img
              className="images5"
              src="/images/NewImages1.jpg"
              alt="img"
              draggable="false"
            />
            
            <img
              className="images5"
              src="/images/NewImages2.jpg"
              alt="img"
              draggable="false"
            />
            <img
              className="images5"
              src="/images/NewImages3.jpg"
              alt="img"
              draggable="false"
            />
            <img
              className="images5"
              src="/images/NewImages4.jpg"
              alt="img"
              draggable="false"
            />
            <img
              className="images5"
              src="/images/NewImages5.jpg"
              alt="img"
              draggable="false"
            />
            <img
              className="images5"
              src="/images/NewImages6.jpg"
              alt="img"
              draggable="false"
            />
            <img
              className="images5"
              src="/images/NewImages7.jpg"
              alt="img"
              draggable="false"
            />
          </div>
          <i id="right" className="fa-solid fa-angle-right"></i>
        </div>
      </div>

     

      
    </div>
  );
};

export default Home;
