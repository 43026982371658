import React, { useEffect, useState } from "react";
import "./Product.css";
import { Link } from 'react-router-dom';

export default function Product() {
  useEffect(()=>{
    window.scrollTo(0,0)

  },[])


  
  return (
    <>
      <div>
        <div className="container-fluid fixed-top bg-white">
          <div className="row">
            <div className="col-md-4">
              <Link to="/">
                <img className="alan2" src="images/logo.png" alt="" />
              </Link>
            </div>
            <div className="col-md-2">
              <div className="box3">
                <img
                  className="locationImage"
                  src="images/location.png"
                  alt=""
                />
                <span>Mumbai</span>
                <i className="arrow down"></i>
              </div>
            </div>
            <div className="col-md-6">
              <div className="box4">
                <div className="search-box">
                  <img className="searchImage" src="images/search.png" alt="" />
                  <input
                    type="text"
                    placeholder="search for services"
                    name=""
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        {/* Other sections */}
        <div className="container-fluid" style={{ marginTop: "130px" }}>
          <div className="row">
            <div className="col-md-4">
              <h1>Bathroom & kitchen cleaning</h1>
              <div className="d-flex text-align">
                <svg
                  width="5%"
                  height="5%"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.333 10a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0zm-7.894-4.694A.476.476 0 009.999 5a.476.476 0 00-.438.306L8.414 8.191l-2.977.25a.48.48 0 00-.414.342.513.513 0 00.143.532l2.268 2.033-.693 3.039a.51.51 0 00.183.518.458.458 0 00.528.022L10 13.298l2.548 1.629a.458.458 0 00.527-.022.51.51 0 00.184-.518l-.693-3.04 2.268-2.032a.513.513 0 00.143-.532.48.48 0 00-.415-.342l-2.976-.25-1.147-2.885z"
                    fill="#572AC8"
                  ></path>
                </svg>
                <p className="mx-2">4.78 (4.1 M bookings)</p>
              </div>
              <div
                className="d-flex"
                style={{
                  backgroundColor: "rgb(245, 245, 245)",
                  alignItems: "center",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5%"
                  height="10%"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M11.307 2.001a3.83 3.83 0 00-1.524.272 3.83 3.83 0 00-1.292.853l-.471.472-.343-.344c-1.431-1.434-3.83-1.689-5.404-.27a3.845 3.845 0 00-1.272 2.757 3.852 3.852 0 001.122 2.822l5.088 5.1a1.14 1.14 0 001.616 0l4.961-4.972c1.431-1.434 1.685-3.839.269-5.416a3.828 3.828 0 00-1.245-.921A3.83 3.83 0 0011.305 2z"
                    fill="#00539a"
                  ></path>
                  <path
                    d="M11.5 4l.424 1.076L13 5.5l-1.076.424L11.5 7l-.424-1.076L10 5.5l1.076-.424L11.5 4z"
                    fill="#fff"
                  ></path>
                </svg>
                <p className="mx-2" style={{ marginTop: "15px" }}>
                  UC Professional Cleaning Guide
                </p>
                <i className="arrow left"></i>
              </div>
              <div
                style={{
                  border: "1px solid black",
                  borderRadius: "4px",
                  padding: "20px",
                  marginTop: "10px",
                }}
              >
                <div className="d-flex mt-4">
                  <p>select a services</p>{" "}
                  <span>
                    <input
                      type="text"
                      style={{
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                    />
                  </span>
                </div>
                <div className="d-flex mt-4 justify-content-center">
                  <div
                    className="card"
                    style={{ width: "18rem", border: "none" }}
                  >
                    <img
                      src="images/Repairs.png"
                      className="card-img-top RepairImages2"
                      alt="..."
                    />
                    <div>
                      <p className="card-text">Carpenters</p>
                    </div>
                  </div>
                  <div
                    className="card"
                    style={{ width: "18rem", border: "none" }}
                  >
                    <img
                      src="images/Repairs1.png"
                      className="card-img-top RepairImages2"
                      alt="..."
                    />
                    <div>
                      <p className="card-text">Electricians</p>
                    </div>
                  </div>
                  <div
                    className="card"
                    style={{ width: "18rem", border: "none" }}
                  >
                    <img
                      src="images/Repairs2.png"
                      className="card-img-top RepairImages2"
                      alt="..."
                    />
                    <div>
                      <p className="card-text">Plumbers</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-4">
                  <div
                    className="card"
                    style={{ width: "18rem", border: "none" }}
                  >
                    <img
                      src="images/Repairs2.png"
                      className="card-img-top RepairImages2"
                      alt="..."
                    />
                    <div>
                      <p className="card-text">Plumbers</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="images/Luxuary.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="images/Luxuary1.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="images/Luxuary3.png"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
              {/* card section */}
              <div className="row mt-4 border border-secondary">
                <div className="col-md-8 border border-secondary">
                  <h2>Super saver deals</h2>
                  <div className="d-flex align-items-center">
                    <p
                      style={{ color: "rgb(110, 66, 229)", fontWeight: "700" }}
                    >
                      Cleaning Guide
                    </p>
                    <i className="arrow2 left"></i>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <p className="supersave">RS. 359 PER BATHROOM</p>
                      <p className="fw-bold">
                        Classic cleaning - 2 bathroom pack
                      </p>
                      <div className="d-flex text-align">
                        <svg
                          width="5%"
                          height="5%"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.333 10a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0zm-7.894-4.694A.476.476 0 009.999 5a.476.476 0 00-.438.306L8.414 8.191l-2.977.25a.48.48 0 00-.414.342.513.513 0 00.143.532l2.268 2.033-.693 3.039a.51.51 0 00.183.518.458.458 0 00.528.022L10 13.298l2.548 1.629a.458.458 0 00.527-.022.51.51 0 00.184-.518l-.693-3.04 2.268-2.032a.513.513 0 00.143-.532.48.48 0 00-.415-.342l-2.976-.25-1.147-2.885z"
                            fill="#572AC8"
                          ></path>
                        </svg>
                        <p className="mx-2">4.79 (804.6K reviews)</p>
                      </div>
                      <div className="d-flex">
                        <p>₹718 </p>
                        <strike className="mx-2">₹798 </strike>
                        <li>2hrs</li>
                      </div>
                      <div className="d-flex">
                        <svg
                          width="5%"
                          height="5%"
                          viewBox="0 0 16 16"
                          fill="#07794C"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                            fill="#07794C"
                          ></path>
                        </svg>
                        <p className="mx-2">₹80 off</p>
                      </div>
                      <hr className="dashed-line" />
                      <li>
                        Hand cleaning of all areas: toilet pot, tiles, basin,
                        exhaust etc.
                      </li>
                      <li>
                        Dirt & stain removal for spotless shine at only
                        ₹359/bathroom{" "}
                      </li>
                      <p
                        style={{
                          color: "rgb(110, 66, 229)",
                          fontWeight: "600",
                          marginTop: "10px",
                        }}
                      >
                        View details
                      </p>
                    </div>
                    <div className="col-4">
                      <img src="images/addcard.png" alt="" />
                      <button
                        style={{ marginLeft: "20px", padding: "0px 30px" }}
                        type="button"
                        className="btn btn-sm btn-outline-primary d-block"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="col-md-12">
                    <h2>Super saver deals</h2>
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          color: "rgb(110, 66, 229)",
                          fontWeight: "700",
                        }}
                      >
                        Cleaning Guide
                      </p>
                      <i className="arrow2 left"></i>
                    </div>
                    <div className="row">
                      <div className="col-8">
                        <p className="supersave">RS. 359 PER BATHROOM</p>
                        <p className="fw-bold">
                          Classic cleaning - 2 bathroom pack
                        </p>
                        <div className="d-flex text-align">
                          <svg
                            width="5%"
                            height="5%"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M18.333 10a8.333 8.333 0 11-16.667 0 8.333 8.333 0 0116.667 0zm-7.894-4.694A.476.476 0 009.999 5a.476.476 0 00-.438.306L8.414 8.191l-2.977.25a.48.48 0 00-.414.342.513.513 0 00.143.532l2.268 2.033-.693 3.039a.51.51 0 00.183.518.458.458 0 00.528.022L10 13.298l2.548 1.629a.458.458 0 00.527-.022.51.51 0 00.184-.518l-.693-3.04 2.268-2.032a.513.513 0 00.143-.532.48.48 0 00-.415-.342l-2.976-.25-1.147-2.885z"
                              fill="#572AC8"
                            ></path>
                          </svg>
                          <p className="mx-2">4.79 (804.6K reviews)</p>
                        </div>
                        <div className="d-flex">
                          <p>₹718 </p>
                          <strike className="mx-2">₹798 </strike>
                          <li>2hrs</li>
                        </div>
                        <div className="d-flex">
                          <svg
                            width="5%"
                            height="5%"
                            viewBox="0 0 16 16"
                            fill="#07794C"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 7.929L8.472 1.4a.997.997 0 00-.904-.274l-5.04 1.008a.5.5 0 00-.393.393l-1.008 5.04a.998.998 0 00.274.904L7.928 15a.999.999 0 001.414 0L15 9.343a.999.999 0 000-1.414zM5.25 6a.75.75 0 110-1.5.75.75 0 010 1.5z"
                              fill="#07794C"
                            ></path>
                          </svg>
                          <p className="mx-2">₹80 off</p>
                        </div>
                        <hr className="dashed-line" />
                        <li>
                          Hand cleaning of all areas: toilet pot, tiles, basin,
                          exhaust etc.
                        </li>
                        <li>
                          Dirt & stain removal for spotless shine at only
                          ₹359/bathroom{" "}
                        </li>
                        <p
                          style={{
                            color: "rgb(110, 66, 229)",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        >
                          View details
                        </p>
                      </div>
                      <div className="col-4">
                        <img src="images/addcard.png" alt="" />
                        <button
                          style={{ marginLeft: "20px", padding: "0px 30px" }}
                          type="button"
                          className="btn btn-sm btn-outline-primary d-block"
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* 3rd card of box */}
                </div>
                <div className="col-md-4">
                  <div className="shopbox mt-3">
                    <div className="cardbox border border-secondary">
                      <img
                        className="shopping-cart d-block mx-auto"
                        src="images/shopping-cart.png"
                        alt=""
                      />
                      <p className="text-center">No items in your cart</p>
                    </div>
                  </div>
                  {/* second box */}
                  <div
                    className="d-flex mt-3 border border-secondary"
                    style={{ padding: "5px", alignItems: "center" }}
                  >
                    <svg
                      width="20%"
                      height="15%"
                      viewBox="0 0 24 24"
                      fill="#05945B"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.75 8.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0zM14.75 15.5a.75.75 0 111.5 0 .75.75 0 01-1.5 0z"
                        fill="#05945B"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm6.5-5.75a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm7 7a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zm-8.93 3.12l9.9-9.9 1.06 1.06-9.9 9.9-1.06-1.06z"
                        fill="#05945B"
                      ></path>
                    </svg>
                    <div
                      className="d-flex mx-2"
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <p>20% off on Kotak Silk cards</p>
                      <p>20% off up to INR 350 </p>
                    </div>
                  </div>
                  {/* third box*/}
                  <div className="d-flex border border-secondary mt-4 p-2">
                    <div className="col-md-8">
                      <h3>UC Promise</h3>
                      <p>&#x2713; Verified Professionals</p>
                      <p>&#x2713; Safe Chemicals</p>
                      <p>&#x2713; Mess Free Experience</p>
                    </div>
                    <div className="col-md-4">
                      <img src="images/promise.png" alt="" />
                    </div>
                  </div>
                </div>
                {/* 2 */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
